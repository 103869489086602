import { UserRole, UserStatus, Language } from './user-entity';
import { BaseResponse } from '../base/baseRes';
import { Prop } from '../store/prop.decorator';
import { CompanyData, RentStatus } from '..';
import { Stock } from '../../src/stock/entity/stock.entity';

export class UserRes extends BaseResponse {
  @Prop() id: string;
  @Prop() email: string;
  @Prop() city: string;
  @Prop() street: string;
  @Prop() postCode: string;
  @Prop() phone: string;
  @Prop() firstName: string;
  @Prop() lastName: string;
  @Prop() role: UserRole;
  @Prop() rentCount: number;
  @Prop() companyData: CompanyData;
  @Prop() tenantId: string | null;
  @Prop() stocks: string[];
}

export interface BoxesSummary {
  count: number;
}

export interface StockSummary {
  id: string;
  name: string;
  boxes: BoxesSummary;
}

export interface StocksByTenant {
  [key: string]: StockSummary[]
}

export interface UserListRes extends UserRes {
  status: UserStatus;
}

export interface UserClientListRes {
  id: string;
  tenantId: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  phone: string;
  allowedBoxesCount: number;
  allowedStocksCount: number;
  stocks?: StockSummary[];

}

export interface UpdateUserReq {
  firstName: string;
  lastName: string;
  phone: string;
  street: string;
  city: string;
  postCode: string;
  companyData: CompanyData;
}

export interface AddUserReq {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  pwd: string;
  pwd2: string;
}

export interface AddInvitedUserReq {
  invitationId: string;
  token: string;
  phone: string;
  firstName: string;
  lastName: string;
  pwd: string;
  pwd2: string;
  tenantId: string;
}

export interface AddInvitedTenantReq {
  userId: string;
  token: string;
  identifier: string;
  name: string;
  pwd: string;
  pwd2: string;
}

export interface InviteWorkerReq {
  email: string,
  role: UserRole,
  tenantId: string,
  stockIds: string[],
}

export interface AddTenantReq {
    // user data
    email: string;
    phone: string;
    tenantId: string;
    // tenant data
    name: string;
}

export interface ChangeRoleReq {
  role: UserRole,
  stocks: string[],
}

export interface UpdatePwdReq {
  oldPwd: string;
  newPwd: string;
  newPwd2: string;
}

export enum UserType {
  staff,
  user,
  client
}

export interface RestorPwdPetition {
  email: string;
}

export interface RestorPwdReq {
  userId: string;
  token: string;
  pwd: string;
  pwd2: string;
}

export class MyLang extends BaseResponse {
  @Prop()
  lang: Language;
}

export interface UpdateWorkerStocksReq {
  userId: string;
  stockIds: string[];
}

export interface UserDetailsRes {
  id: string;
  email: string;
  city: string;
  street: string;
  postCode: string;
  phone: string;
  firstName: string;
  lastName: string;
  rentCount: number;
  companyData: CompanyData;
  activeRents: ActiveRentsForUserDetails[]
}

export interface ActiveRentsForUserDetails {
  id: string;
  startAt: Date;
  finishAt: Date;
  number: string;
  status: RentStatus;
}

export interface SendSmsReq {
  sendToOnesThatRent: boolean;
  text: string;
  stockId?: string;
}
