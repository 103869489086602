export enum ApiErrorCode {
  OtherError,
  NoSuchObjectWithThisId,
  ObjectNotUniuque,
  InvalidLoginData,
  InvalidData,
  FormValidationError,
  UserExist,
  InvalidPrice,
  NoFreeBoxInThisSize,
  PaymentError,
  PayFirst,
  CantChangeThisBoxOnThisStatus,
  TooLateForCahnge,
  NotFreeBox,
  InvalidDiscountCode,
  CardHasBeenUsedWithDiscount,
  InvalidBoxNumber,
  CreatingInvoiceFailure,
  SendingEmailWithInvoiceFailure,
  InvalidLang,
  StckIdIsRequiredForWorkerRole,
  NoFoundBoxNumberOnStock,
  ActionForebiden,
  InvalidCoordinate,
  UAreTooFarAway,
  StockHasActiveDiscont,
  InvalidBlik,
  InvalidGroup,
  BoxNumberIsReserved,
  PhoneIsRequiredForRent,
  InvalidUserType,
  CompanyDataIsRequired,
  BoxIsReserved,
  UserIsBlocked,
  YouHaveNoPaidBoxes,
  NoAccessToTheGate,
  FirstActiveUser,
  PaymentIsntBlikType,
  PaymentIsntTransferType,
  PaymentIsntCardType,
  InvalidTaxValue,
  ErrorConnectingToGate,
  ForbiddenFileFormat,
  PhoneNumberIsAlreadyInUse,
  IdentifierAlreadyInUse,
  TenantDoesNotExist,
  CanNotDeleteNotEmptyBox,
  NeedAtLestOnePricingPeriod,
  PricesAndPeriodsNeedsToBeInAscendingOrder,
  PeriodMax28Days,
  PeriodMax4Weeks,
  PeriodMax12Months,
  PeriodAtLeast1,
  YouNeedToSetPriceForOneDay,
  RentPeriodTooShort,
  NoDiscountSet,
  GiftCodeInvalid,
  CantRemoveUsedGift,
  TenantNotActive,
  CanNotRemoveGroupWithRents,
  NoInvoiceServiceData,
  BoxesLimitReached,
  TryingToAddTooManyBoxes,
  StocksLimitReached
}
