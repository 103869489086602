import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldType, boxesList } from 'smartbox-tables';
import { BoxListRes, IStock } from 'smartbox-types';
import { Alert, ApiTable } from '../../../../../../../components/Common';
import { Button } from '../../../../../../../components/Branded';
import { ButtonsContainer, SimpleHeader } from '../../../../../../../components/Layout';
import { __ } from '../../../../../../../helpers/i18n';
import { InteractiveTableSchema } from '../../../../../../../utils/table-schema-utils';
import UserService from '../../../../../../../services/user-service';
import { showModal } from '../../../../../../../actions/modal';
import { ConfirmModal } from '../../../../../../../components/Common';
import ApiService from '../../../../../../../services/api-service';
import { successNotification } from '../../../../../../../actions/notifications';
import { refreshApiTableData } from '../../../../../../../actions/api-table';
import { useParams } from 'react-router';
import { Edit } from '../Edit';
import { ApplicationState } from '../../../../../../../reducers';
import BoxDetail from '../../../../../../../views/DashboardCommon/BoxMapManagement/BoxDetail';
import { BoxStatus } from './BoxStatus';

const List = () => {
    const user = useSelector((state: ApplicationState) => state.user.details!);
    const dispatch = useDispatch();
    const [stock, setStock] = useState<IStock>();
    const { stockId } = useParams<{ stockId: string }>();

    const getStock = async () => {
        await ApiService.callFetch('GET', `stock/${stockId}`, (res: IStock) => {
            setStock(res);
        })
    }

    const deleteBox = async (boxId: string) => {
        await ApiService.callFetch('DELETE', `box/${boxId}`, () => {
            dispatch(successNotification('application.boxDeleted'));
            dispatch(refreshApiTableData());
        })
    }

    const confirmBoxDelete = (boxId: string) => {
        dispatch(showModal(<ConfirmModal
            approveButtonText='application.delete'
            mainText='application.deleteBoxText'
            approveAction={() => deleteBox(boxId)}
        />))
    }

    useEffect(() => { getStock() }, [stockId])

    const scheme = new InteractiveTableSchema(boxesList)
        .modifyFieldStatic('pricing', {
            name: __('application.pricingIsSet'),
            field: 'pricing',
            mobile: true,
            defaultSort: true,
            type: FieldType.Custom,
            customRender: (value, field, boxData) => (boxData.pricing && boxData.pricing.length > 0) ? <Alert simple type='success' text='application.yes' /> : <Alert simple type='error' text='application.no' />
        })
        .modifyFieldStatic('status', {
            name: __('application.state'),
            field: 'status',
            mobile: true,
            defaultSort: true,
            type: FieldType.Custom,
            customRender: (value, field, boxData) => <BoxStatus box={boxData} stock={stock} />,
        })
        .addFieldAtTheEnd({
            name: __('application.actions'),
            field: 'actions',
            mobile: true,
            buttons: [
                {
                    name: 'application.manage',
                    type: 'primary',
                    click: (data: BoxListRes) => dispatch(showModal(<BoxDetail box={data} stockId={stockId} />)),
                },
                {
                    name: 'application.edit',
                    type: 'secondary',
                    click: (data: BoxListRes) => dispatch(showModal(<Edit id={data.id} stockId={data.stockId} />)),
                },
                {
                    name: 'application.delete',
                    type: 'danger',
                    click: (data: BoxListRes) => confirmBoxDelete(data.id),
                }
            ],
        });

    return (
        <>
            <SimpleHeader title='application.boxesList' />
            <ButtonsContainer>
                <Button text='application.addBox' primary to={`/${UserService.getSlugByRole(user.role)}/locations/stocks/${stockId}/boxes/add`} />
                <Button text='application.addBoxes' secondary to={`/${UserService.getSlugByRole(user.role)}/locations/stocks/${stockId}/boxes/add-multiple`} />
            </ButtonsContainer>
            <ApiTable scheme={scheme} apiEndpointSubUrl={`box/list/${stockId}`} />
        </>
    );
}

export { List }